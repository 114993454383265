import { createRoot } from 'react-dom/client'
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from 'react-router';
import Datatables, { loadDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { ActiveButton, DeactiveButton, DeleteButton, DragButton, EditButton, ViewButton } from '../../components/ButtonHelper';
import { ConfirmationModal, fetchData, getPermission } from '../../components/Helper';
import { CHOICE_EDIT, CHOICE_LIST, CHOICE_QUESTIONS_CHANGE_ORDER, CHOICE_QUESTIONS_CHANGE_STATUS, CHOICE_QUESTIONS_LIST, SET_QUESTIONS_MULTIPLE_OPTION } from '../../components/ApiRoutes';
import Context from '../../components/Context';

function ChoiceQuestionList() {
    const navigate = useNavigate()
    const [routeDetails, setRouteDetails] = useState()
    const choiceId = useParams().choice_id
    const [deleteItem, setDeleteItem] = useState()
    const [context] = useContext(Context)
    const [access, setAccess] = useState({view: false, add: false, edit: false, delete: false})

    useEffect(() => setAccess(getPermission(context, "category_management__choice_list")), [context])

    const [dt] = useState({
        dt_url: `${CHOICE_QUESTIONS_LIST}?choice_id=${choiceId}`,
        dt_name: 'choice_question_list',
        dt_order: [[1, 'asc']],
        dt_rowReorder: {
            url: CHOICE_QUESTIONS_CHANGE_ORDER,
        },
        dt_column: [
            { data: "id", name: 'id', title: "#ID" },
            { data: "order", name: 'order', title: "#Order", visible: false },
            { data: "question", name: 'question', title: "Question" },
            { data: "multiple_option", name: 'multiple_option', title: "Multiple Choice" },
            { data: "status", name: 'status', title: "Status" },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],
        dt_column_defs: [
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <button type="button" className={`w-50px p-0 btn btn-sm btn-${records.multiple_option === 'Yes' ? 'success' : 'danger'}`} onClick={() => setMultipleOption(records.id)}>
                            {records.multiple_option}
                        </button>
                    </>)
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        {records.status === "active" && <span className="text-green fw-medium">Active</span>}
                        {records.status === "inactive" && <span className="text-danger fw-medium">Inactive</span>}
                    </>)
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    let permission = getPermission(context, "category_management__choice_list")

                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            {permission.edit && <>
                                {records.status === "active" ? <DeactiveButton action={() => handleChangeStatus('inactive', records.id)} /> : <ActiveButton action={() => handleChangeStatus('active', records.id)} />}
                                <EditButton action={() => navigate(`/erp/choice/${choiceId}/edit/${records.id}`, { state: { data: records } })} />
                            </>}
                            
                            {permission.view && <ViewButton action={() => navigate(`/erp/choice/${choiceId}/${records.id}`)} />}
                            {permission.delete && <DeleteButton modal_id="#confirmationModal" action={() => setDeleteItem(records.id)} />}
                            {permission.edit && <DragButton />}
                        </div>
                    </>)
                }
            }
        ],
    })

    useEffect(() => {
        fetchData(`${CHOICE_EDIT}/${choiceId}`, 'get', '', true, false, (res) => {
            if (res.status) {
                setRouteDetails(res.data)
            }
        }, false, false, false)
        reloadUrlDataTable(dt, CHOICE_LIST)
    }, [dt, choiceId])

    const handleChangeStatus = (status, id) => {
        fetchData(CHOICE_QUESTIONS_CHANGE_STATUS, 'POST', { 'id': id, 'status': status }, true, false, (res) => {
            loadDataTable(dt)
        }, false, false, false)
    }

    const setMultipleOption = (id) => {
        fetchData(SET_QUESTIONS_MULTIPLE_OPTION, 'POST', { 'id': id }, true, false, (res) => {
            loadDataTable(dt)
        }, false, false, false)
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col mb-3">
                            <h6 className="m-0 heading-lines fw-medium pb-2">
                                <Link to={`/erp/choice`} className="text-decoration-none" title="Choice List">Choice List </Link>
                                <i className="bi bi-chevron-double-right fs-14px"></i> {routeDetails && routeDetails.name}
                            </h6>
                        </div>
                        <div className="col-auto mb-2">
                            {access.add && <Link to={`/erp/choice/${choiceId}/create`} className="btn btn-blue btn-sm px-3 mb-1 me-2" title="Add Question"><i className="bi bi-plus-circle-fill me-1"></i> Add Question</Link>}
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <Datatables dt_name="choice_question_list" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ConfirmationModal action={() => handleChangeStatus('delete', deleteItem)} />
        </>
    )
}

export default ChoiceQuestionList