import { createRoot } from 'react-dom/client'
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router';
import Datatables, { loadDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { ActiveButton, DeactiveButton, DeleteButton, EditButton } from '../../components/ButtonHelper';
import { ConfirmationModal, fetchData, getPermission } from '../../components/Helper';
import { PRE_CONSTRUCTION_CHANGE_STATUS, PRE_CONSTRUCTION_LIST } from '../../components/ApiRoutes';
import Context from '../../components/Context';

function PreConstructionList() {
    const navigate = useNavigate()
    const [deleteItem, setDeleteItem] = useState()
    const [context] = useContext(Context)
    const [access, setAccess] = useState({view: false, add: false, edit: false, delete: false})

    useEffect(() => setAccess(getPermission(context, "category_management__pre_construction")), [context])

    const [dt] = useState({
        dt_url: PRE_CONSTRUCTION_LIST,
        dt_name: 'pre_construction_list',
        dt_order: [[0, 'asc']],
        dt_column: [
            { data: "id", name: 'id', title: "#ID" },
            { data: "name", name: 'name', title: "Name" },
            { data: "status", name: 'status', title: "Status" },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],
        dt_column_defs: [
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        {records.status === "active" && <span className="text-green fw-medium">Active</span>}
                        {records.status === "inactive" && <span className="text-danger fw-medium">Inactive</span>}
                    </>)
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    let permission = getPermission(context, "category_management__pre_construction")

                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            {permission.edit && <>
                                {records.status === "active" ? <DeactiveButton action={() => handleChangeStatus('inactive', records.id)} /> : <ActiveButton action={() => handleChangeStatus('active', records.id)} />}
                                <EditButton action={() => navigate(`/erp/pre-construction/edit/${records.id}`)} />
                            </>}
                            
                            {permission.delete && <DeleteButton modal_id="#confirmationModal" action={() => setDeleteItem(records.id)} />}
                        </div>
                    </>)
                }
            }
        ],
    })

    useEffect(() => {
        reloadUrlDataTable(dt, PRE_CONSTRUCTION_LIST)
    }, [dt])

    const handleChangeStatus = (status, id) => {
        fetchData(PRE_CONSTRUCTION_CHANGE_STATUS, 'POST', { 'id': id, 'status': status }, true, false, (res) => {
            loadDataTable(dt)
        }, false, false, false)
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col mb-3">
                            <h6 className="m-0 heading-lines fw-medium pb-2">Pre Construction List</h6>
                        </div>
                        <div className="col-auto mb-2">
                            {access.add && <Link to={`/erp/pre-construction/create`} className="btn btn-blue btn-sm px-3 mb-1 me-2" title="Add Pre Construction"><i className="bi bi-plus-circle-fill me-1"></i> Add Pre Construction</Link>}
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <Datatables dt_name="pre_construction_list" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ConfirmationModal action={() => handleChangeStatus('delete', deleteItem)} />
        </>
    )
}

export default PreConstructionList