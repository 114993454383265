import { useEffect, useState } from "react";
import { GET_ACTIVE_CONSTRUCTION_LIST, GET_ACTIVE_DESIGN_LIST, GET_ACTIVE_POST_CONSTRUCTION_LIST, GET_ACTIVE_PRE_CONSTRUCTION_LIST, PROJECT_TASK_CREATE, PROJECT_TASK_UPDATE } from "../../../components/ApiRoutes";
import { fetchData, initialFormState, ModalSection, validateForm } from "../../../components/Helper";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { ModalInputField, ModalSelectField, ModalSubmitButton } from "../../../components/FormHelper";

export const CreateTask = (props) => {
    const [tinyloader, setTinyloader] = useState(false);
    const [designList, setDesignList] = useState();
    const [dueDate, setDueDate] = useState(new Date());
    const [data, setData] = useState();
    const [operation, setOperationa] = useState();

    useEffect(() => {
        if(props.data){
            setData(props.data)
            setDueDate(new Date(props.data.due_date))
            setOperationa("Update")
        }else{
            setData("")
            setDueDate(new Date())
            setOperationa("Create")
        }
    }, [props.data])

    useEffect(() => {
        document.getElementById('createTaskModal').addEventListener('show.bs.modal', function (event) {
            if(!props.subTask && !designList){
                let api_url = ''
                let project_step = event.target.dataset.attr
                if (project_step === 'design-and-approval') {
                    api_url = GET_ACTIVE_DESIGN_LIST
                } else if (project_step === 'pre-construction') {
                    api_url = GET_ACTIVE_PRE_CONSTRUCTION_LIST
                } else if (project_step === 'construction') {
                    api_url = GET_ACTIVE_CONSTRUCTION_LIST
                } else if (project_step === 'post-construction') {
                    api_url = GET_ACTIVE_POST_CONSTRUCTION_LIST
                }

                fetchData(api_url, 'get', '', true, false, (res) => {
                    if (res.status) {
                        setDesignList(res.data)
                    }
                })
            }
            
            initialFormState("createTaskForm")
        })
    }, [props.subTask, designList])

    const createList = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)

            let formdata = new FormData(document.getElementById('createTaskForm'))
            formdata.append('project_id', props.projectId)
            formdata.append('task_category', props.projectStep)
            if(props.subTask){
                formdata.append('project_task_id', props.subTask)
            }

            if(operation === 'Update'){
                formdata.append('id', data.id)
            }

            fetchData(operation === 'Create' ? PROJECT_TASK_CREATE : PROJECT_TASK_UPDATE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    props.reloadTable()
                    document.getElementById('form-project-add-task').click()
                    setData("")
                    setDueDate(new Date())
                    setOperationa("Create")
                }
            })
        }
    }

    return (
        <ModalSection title={`${operation} ${props.subTask ? "Sub Task" : "Task"}`} modal_id="createTaskModal" size="lg" close_btn_id="form-project-add-task" func={props.closeModal} data_attr={props.projectStep}>
            <form action="#" method="post" className="needs-validation" id="createTaskForm" noValidate>
                {!props.subTask && designList && <>
                    <ModalSelectField name="task" value={data && data.task_name} required>
                        {designList.map((design, index) => <option key={index} value={design.name}>{design.name}</option>)}
                    </ModalSelectField>
                    <div className="row mx-0 mb-4">
                        <label htmlFor="date" className="col-sm-3 col-xxl-6 col-form-label">Due Date<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                        <div className="col-sm-9 col-lg-6 col-xxl-6">
                            <DatePicker
                                selected={dueDate}
                                onChange={(date) => setDueDate(date)}
                                className="form-control"
                                dateFormatCalendar={"MMM yyyy"}
                                minDate={new Date()}
                                onChangeRaw={(e) => e.preventDefault()}
                                id="date"
                                name="date"
                                showMonthDropdown
                                showYearDropdown
                                withPortal
                            />
                        </div>
                    </div>
                    <ModalSubmitButton title="Save" load={tinyloader} action={createList} />
                </>}

                {props.subTask ? <>
                    <ModalInputField name="task" value={data && data.task_name} required />
                    <div className="row mx-0 mb-4">
                        <label htmlFor="date" className="col-sm-3 col-xxl-6 col-form-label">Due Date<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                        <div className="col-sm-9 col-lg-6 col-xxl-6">
                            <DatePicker
                                selected={dueDate}
                                onChange={(date) => setDueDate(date)}
                                className="form-control"
                                dateFormatCalendar={"MMM yyyy"}
                                minDate={new Date()}
                                onChangeRaw={(e) => e.preventDefault()}
                                id="date"
                                name="date"
                                showMonthDropdown
                                showYearDropdown
                                withPortal
                            />
                        </div>
                    </div>
                    <ModalSubmitButton title="Save" load={tinyloader} action={createList} />
                </> : ''}
            </form>
        </ModalSection>
    )
}