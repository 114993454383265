import { useContext, useEffect, useState } from "react"
import { createRoot } from "react-dom/client"
import { useParams } from "react-router-dom"
import { PROJECT_TASK_CHANGE_STATUS, PROJECT_TASK_LIST } from "../../../components/ApiRoutes"
import { ActiveButton, AttachmentButtonModal, DeleteButton, EditButtonModal, ListButtonModal, NoteButtonModal, OnGoingButton } from "../../../components/ButtonHelper"
import DataTables, { reloadUrlDataTable } from "../../../components/Datatables"
import Context from "../../../components/Context"
import { CreateTask } from "../Components/CreateTask"
import { Note } from "../Components/Note"
import { Attachment } from "../Components/Attachment"
import { ConfirmationModal, fetchData, ModalSection } from "../../../components/Helper"
import { now } from "lodash"

const TaskList = (props) => {
    const [context] = useContext(Context);
    const projectId = useParams().project_id
    const projectStep = useParams().project_step
    const [reloadTable, setReloadTable] = useState();
    const [taskId, setTaskId] = useState();
    const [taskDetail, setTaskDetail] = useState();
    const [previousModal, setPreviousModal] = useState();
    const step = `project_management__`+projectStep.replace(/-/g, '_')
    const [deleteTaskId, setDeleteTaskId] = useState()
    const [taskData, setTaskData] = useState()

    const showButton = context && context.permissions && `${context.permissions[step]}`

    const [dt] = useState({
        dt_url: `${PROJECT_TASK_LIST}/${projectId}/${projectStep}`,
        dt_name: `project_task_list`,
        dt_column: [
            { data: "created_at", name: 'created_at', title: "Created" },
            { data: "created_user.name", name: 'createdUser.name', title: "Created By" },
            { data: "task_name", name: 'task_name', title: "Task" },
            { data: "due_date", name: 'due_date', title: "Due Date" },
            { data: "status", name: 'status', title: "Status" },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],
        dt_column_defs: [
            {
                targets: 4,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        {records.status === "pending" && <span className="text-warning fw-medium">Pending</span>}
                        {records.status === "on-going" && <span className="text-info fw-medium">On Going</span>}
                        {records.status === "completed" && <span className="text-success fw-medium">Completed</span>}
                    </>)
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            {context && context.permissions && 
                            <> 
                                {showButton.includes('edit') && <>
                                    {records.status === "pending" && <OnGoingButton action={() => handleChangeStatus('on-going', records.id)} />}
                                    {records.status === "on-going" && <ActiveButton title="Completed" action={() => handleChangeStatus('completed', records.id)} />}
                                </>}

                                {showButton.includes('view') && <ListButtonModal title="Sub Task List" id={"opensubTaskModalBtn_" + records.id} modal_id="#subTaskModal" action={() => subTaskList(records.id, records.task_category)} />}
                                {context.permissions.notes.includes('view') && <NoteButtonModal modal_id="#noteModal" action={() => openModal(records.id, "note")} />}
                                {context.permissions.attachments.includes('view') && <AttachmentButtonModal modal_id="#attachmentModal" action={() => openModal(records.id, "attachment")} />}
                                {showButton.includes('edit') && <EditButtonModal modal_id="#createTaskModal" action={() => setTaskData(records)} />}
                                {showButton.includes('delete') && <DeleteButton modal_id="#taskDeleteModal" action={() => setDeleteTaskId(records.id)} />}
                            </>}
                        </div>
                    </>)
                }
            }
        ],
    })

    useEffect(() => {
        if (projectStep !== 'details' && projectStep !== 'choice-list') {
            reloadUrlDataTable(dt, `${PROJECT_TASK_LIST}/${projectId}/${projectStep}`)
        }
    }, [dt, projectId, projectStep, reloadTable])

    const openModal = (id, type) => {
        setTaskDetail(type)
        setTaskId(id)
    }


    const subTaskList = (id, type) => {
        let dt_inventory_spend_list = {
            dt_url: `${PROJECT_TASK_LIST}/${projectId}/${type}/${id}`,
            dt_name: `sub_task_list`,
            dt_column: [
                { data: "created_at", name: 'created_at', title: "Created" },
                { data: "created_user.name", name: 'createdUser.name', title: "Created By" },
                { data: "task_name", name: 'task_name', title: "Task" },
                { data: "due_date", name: 'due_date', title: "Due Date" },
                { data: "status", name: 'status', title: "Status" },
                { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
            ],
            dt_column_defs: [
                {
                    targets: 4,
                    createdCell: (td, cellData, records, row, col) => {
                        createRoot(td).render(<>
                            {records.status === "pending" && <span className="text-warning fw-medium">Pending</span>}
                            {records.status === "on-going" && <span className="text-info fw-medium">On Going</span>}
                            {records.status === "completed" && <span className="text-success fw-medium">Completed</span>}
                        </>)
                    }
                },
                {
                    targets: 5,
                    createdCell: (td, cellData, records, row, col) => {
                        createRoot(td).render(<>
                            <div className="d-flex text-nowrap">
                            {context && context.permissions && 
                            <> 
                                {showButton.includes('edit') && <>
                                    {records.status === "pending" && <OnGoingButton action={() => changeStatus('on-going', records.id)} />}
                                    {records.status === "on-going" && <ActiveButton title="Completed" action={() => changeStatus('completed', records.id)} />}
                                </>}
                                
                                {context.permissions.notes.includes('view') && <NoteButtonModal modal_id="#noteModal" action={() => openModal(records.id, "note")} />}
                                {context.permissions.attachments.includes('view') && <AttachmentButtonModal modal_id="#attachmentModal" action={() => openModal(records.id, "attachment")} />}
                                {showButton.includes('edit') && <EditButtonModal modal_id="#createTaskModal" action={() => setTaskData(records)} />}
                                {showButton.includes('delete') && <DeleteButton modal_id="#taskDeleteModal" action={() => setDeleteTaskId(records.id)} />}
                            </>}
                            </div>
                        </>)
                    }
                }
            ],
        };

        setPreviousModal(id)
        reloadUrlDataTable(dt_inventory_spend_list, `${PROJECT_TASK_LIST}/${projectId}/${type}/${id}`)

        const changeStatus = (status, current_id) => {
            fetchData(PROJECT_TASK_CHANGE_STATUS, 'POST', { 'id': current_id, 'status': status }, true, false, (res) => {
                reloadUrlDataTable(dt_inventory_spend_list, `${PROJECT_TASK_LIST}/${projectId}/${type}/${id}`)
            })
        }
    }

    const reloadDataTable = () => {
        if(previousModal){
            document.getElementById("opensubTaskModalBtn_" + previousModal).click()
        }else{
            setReloadTable(now)
        }
    }

    const closeModal = () => {
        if(previousModal){
            document.getElementById("opensubTaskModalBtn_" + previousModal).click()
        }
    }

    const handleChangeStatus = (status, id) => {
        fetchData(PROJECT_TASK_CHANGE_STATUS, 'POST', { 'id': id, 'status': status }, true, false, (res) => {
            reloadDataTable()
        })
    }

    return (
        <>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                <div className="text-orange mb-1"></div>
                <div className="">
                    {showButton.includes('add') &&
                        <button type="button" className="btn btn-blue btn-sm px-3 mb-1 me-2" data-bs-toggle="modal" data-bs-target="#createTaskModal" onClick={() => setTaskData(null)}><i className="bi bi-plus-circle-fill me-1"></i> Create Task</button>
                    }
                </div>
            </div>

            <div className="table-responsive p-1">
                <DataTables dt_name={`project_task_list`} />
            </div>

            <ModalSection title="Sub Task List" modal_id="subTaskModal" size="xl" func={() => setPreviousModal(null)}>
                {showButton.includes('add') &&
                    <div className="text-end">
                        <button type="button" className="btn btn-blue btn-sm px-3 mb-1 me-1" data-bs-toggle="modal" data-bs-target="#createTaskModal" onClick={() => setTaskData(null)}><i className="bi bi-plus-circle-fill me-1"></i> Create Sub Task</button>
                    </div>
                }
                <div className="table-responsive p-1">
                    <DataTables dataPageLength={10} dt_name="sub_task_list" />
                </div>
            </ModalSection>

            <CreateTask data={taskData} previousModal={previousModal} projectId={projectId} projectStep={projectStep} reloadTable={reloadDataTable} subTask={previousModal} closeModal={closeModal} />
            <Note permissions={context && context.permissions && context.permissions.notes} projectId={projectId} projectStep={projectStep} taskId={taskId} type={taskDetail} closeModal={closeModal} />
            <Attachment projectId={projectId} projectStep={projectStep} taskId={taskId} type={taskDetail} closeModal={closeModal} />

            <ConfirmationModal modal_id="taskDeleteModal" title="Delete Task" action={() => handleChangeStatus('delete', deleteTaskId)}>
                <p className="">Are you sure want to delete this {previousModal ? "Sub Task" : "Task"}?</p>
            </ConfirmationModal>
        </>
    )
}

export default TaskList