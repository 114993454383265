import React, { useEffect, useState } from 'react';
import { Route, Routes } from "react-router-dom";
import AccountList from '../modules/Account/AccountList';
import AccountCreate from '../modules/Account/AccountCreate';
import Dashboard from '../modules/Dashboard/Dashboard';

import Login from '../modules/Login';
import ForgotPassword from '../modules/ForgotPassword';
import ResetPassword from '../modules/ResetPassword';
import Context from './Context';
import { fetchData } from './Helper';
import Layout from './Layout';
import NotFound from './NotFound';
import VerifyEmail from '../modules/VerifyEmail';
import ChoiceList from '../modules/ChoiceList/ChoiceList';
import ChoiceCreate from '../modules/ChoiceList/ChoiceCreate';
import ChoiceQuestionList from '../modules/ChoiceList/ChoiceQuestionList';
import ChoiceQuestionListCreate from '../modules/ChoiceList/ChoiceQuestionListCreate';
import ChoiceAnswerList from '../modules/ChoiceList/ChoiceAnswerList';
import ChoiceAnswerListCreate from '../modules/ChoiceList/ChoiceAnswerListCreate';
import DesignList from '../modules/DesignAndApproval/DesignList';
import DesignCreate from '../modules/DesignAndApproval/DesignCreate';
import InventoryList from '../modules/Inventory/InventoryList';
import ItemAdd from '../modules/Inventory/ItemAdd';
import SubItemList from '../modules/Inventory/SubItemList';
import SubItemAdd from '../modules/Inventory/SubItemAdd';
import Projects from '../modules/Project/Projects';
import PreConstructionList from '../modules/PreConstruction/PreConstructionList';
import PreConstructionCreate from '../modules/PreConstruction/PreConstructionCreate';
import ConstructionList from '../modules/Construction/ConstructionList';
import ConstructionCreate from '../modules/Construction/ConstructionCreate';
import PostConstructionList from '../modules/PostConstruction/PostConstructionList';
import PostConstructionCreate from '../modules/PostConstruction/PostConstructionCreate';
import ProjectAdd from '../modules/Project/ProjectAdd';
import ProjectEdit from '../modules/Project/ProjectEdit';
import ChangePassword from '../modules/Account/ChangePassword';
import EditProfile from '../modules/Account/Editprofile';
import Systemlogs from '../modules/Account/Systemlogs';

export default function Routing() {
    const [context, setContext] = useState();
    const [token] = useState(localStorage.getItem('adb-token'));
    useEffect(() => {
        fetchData('get-site-data', 'GET', '', false, false, (res) => {
            if (res.status) {
                setContext(prevState => ({
                    ...prevState,
                    site: res.data
                }))
            }
        })

        if (token) {
            fetchData('get-auth-user', 'GET', '', true, false, (res) => {
                if (res.status) {
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.data,
                        permissions: res.data.permissions
                    }))
                }
            })
        }
    }, [token]);

    return (
        <>
            <Context.Provider value={[context, setContext]}>
                <Routes>
                    <Route path="/" exact caseSensitive={false} element={<Layout hideHeader={true} />}>
                        <Route path="/" caseSensitive={false} element={<Login />} />
                        <Route path="/login" caseSensitive={false} element={<Login />} />
                        <Route path="/forgot-password" caseSensitive={false} element={<ForgotPassword />} />
                        <Route path="/reset-password" caseSensitive={false} element={<ResetPassword />} />
                        <Route path="/verify-email" caseSensitive={false} element={<VerifyEmail />} />
                        <Route path="*" caseSensitive={false} element={<NotFound />} />
                    </Route>

                    <Route path="/erp/" exact caseSensitive={false} element={<Layout hideHeader={false} />}>
                        <Route path="" caseSensitive={false} element={<Dashboard />} />
                        <Route path="dashboard" caseSensitive={false} element={<Dashboard />} />
                        <Route path="logs" caseSensitive={false} element={<Systemlogs />} > </Route>
                        <Route path="change-password" caseSensitive={false} element={<ChangePassword />} />
                        <Route path="my-profile" caseSensitive={false} element={<EditProfile />} />
                        {context && context.permissions && <>

                            {/* User Management Routes */}
                            {(context.permissions.user_management__admin || context.permissions.user_management__project_manager || context.permissions.user_management__client) && <>

                                {((context.permissions.user_management__admin && context.permissions.user_management__admin.includes('view')) || (context.permissions.user_management__project_manager && context.permissions.user_management__project_manager.includes('view')) || (context.permissions.user_management__client && context.permissions.user_management__client.includes('view'))) &&
                                    <Route path="user-management/:account_type/accounts" caseSensitive={false} element={<AccountList />} />
                                }

                                {((context.permissions.user_management__admin && context.permissions.user_management__admin.includes('add')) || (context.permissions.user_management__project_manager && context.permissions.user_management__project_manager.includes('add')) || (context.permissions.user_management__client && context.permissions.user_management__client.includes('add'))) &&
                                    <Route path="user-management/:account_type/create" caseSensitive={false} element={<AccountCreate />} />
                                }

                                {((context.permissions.user_management__admin && context.permissions.user_management__admin.includes('edit')) || (context.permissions.user_management__project_manager && context.permissions.user_management__project_manager.includes('edit')) || (context.permissions.user_management__client && context.permissions.user_management__client.includes('edit'))) &&
                                    <Route path="user-management/:account_type/edit/:id" caseSensitive={false} element={<AccountCreate />} />
                                }

                            </>}

                            {/* Choice Management Routes */}
                            {context.permissions.category_management__choice_list && <>

                                {context.permissions.category_management__choice_list.includes('view') && <>
                                    <Route path="choice" caseSensitive={false} element={<ChoiceList />} />
                                    <Route path="choice/:choice_id" caseSensitive={false} element={<ChoiceQuestionList />} />
                                    <Route path="choice/:choice_id/:question_id" caseSensitive={false} element={<ChoiceAnswerList />} />
                                </>}

                                {context.permissions.category_management__choice_list.includes('add') && <>
                                    <Route path="choice/create" caseSensitive={false} element={<ChoiceCreate />} />
                                    <Route path="choice/:choice_id/create" caseSensitive={false} element={<ChoiceQuestionListCreate />} />
                                    <Route path="choice/:choice_id/:question_id/create" caseSensitive={false} element={<ChoiceAnswerListCreate />} />
                                </>}

                                {context.permissions.category_management__choice_list.includes('edit') && <>
                                    <Route path="choice/edit/:id" caseSensitive={false} element={<ChoiceCreate />} />
                                    <Route path="choice/:choice_id/edit/:question_id" caseSensitive={false} element={<ChoiceQuestionListCreate />} />
                                    <Route path="choice/:choice_id/:question_id/edit/:answer_id" caseSensitive={false} element={<ChoiceAnswerListCreate />} />
                                </>}

                            </>}

                            {/* Design and approval Management Routes */}
                            {context.permissions.category_management__design_and_approval && <>

                                {context.permissions.category_management__design_and_approval.includes('view') && <Route path="design-approval" caseSensitive={false} element={<DesignList />} />}
                                {context.permissions.category_management__design_and_approval.includes('add') && <Route path="design-approval/create" caseSensitive={false} element={<DesignCreate />} />}
                                {context.permissions.category_management__design_and_approval.includes('edit') && <Route path="design-approval/edit/:id" caseSensitive={false} element={<DesignCreate />} />}

                            </>}

                            {/* Pre-construction Management Routes */}
                            {context.permissions.category_management__pre_construction && <>

                                {context.permissions.category_management__pre_construction.includes('view') && <Route path="pre-construction" caseSensitive={false} element={<PreConstructionList />} />}
                                {context.permissions.category_management__pre_construction.includes('add') && <Route path="pre-construction/create" caseSensitive={false} element={<PreConstructionCreate />} />}
                                {context.permissions.category_management__pre_construction.includes('edit') && <Route path="pre-construction/edit/:id" caseSensitive={false} element={<PreConstructionCreate />} />}

                            </>}

                            {/* Construction Management Routes */}
                            {context.permissions.category_management__construction && <>

                                {context.permissions.category_management__construction.includes('view') && <Route path="construction" caseSensitive={false} element={<ConstructionList />} />}
                                {context.permissions.category_management__construction.includes('add') && <Route path="construction/create" caseSensitive={false} element={<ConstructionCreate />} />}
                                {context.permissions.category_management__construction.includes('edit') && <Route path="construction/edit/:id" caseSensitive={false} element={<ConstructionCreate />} />}

                            </>}

                            {/* Post-construction Management Routes */}
                            {context.permissions.category_management__post_construction && <>

                                {context.permissions.category_management__post_construction.includes('view') && <Route path="post-construction" caseSensitive={false} element={<PostConstructionList />} />}
                                {context.permissions.category_management__post_construction.includes('add') && <Route path="post-construction/create" caseSensitive={false} element={<PostConstructionCreate />} />}
                                {context.permissions.category_management__post_construction.includes('edit') && <Route path="post-construction/edit/:id" caseSensitive={false} element={<PostConstructionCreate />} />}

                            </>}

                            {/* Inventory Management Routes */}
                            {context.permissions.inventory && <>

                                {context.permissions.inventory.includes('view') && <>
                                    <Route path="inventory" caseSensitive={false} element={<InventoryList />} />
                                    <Route path="inventory/:item_id" caseSensitive={false} element={<SubItemList />} />
                                </>}

                                {context.permissions.inventory.includes('add') && <>
                                    <Route path="inventory/add" caseSensitive={false} element={<ItemAdd />} />
                                    <Route path="inventory/:item_id/add" caseSensitive={false} element={<SubItemAdd />} />
                                </>}

                                {context.permissions.inventory.includes('edit') && <>
                                    <Route path="inventory/edit/:id" caseSensitive={false} element={<ItemAdd />} />
                                    <Route path="inventory/:item_id/edit/:sub_item_id" caseSensitive={false} element={<SubItemAdd />} />
                                </>}

                            </>}

                            {/* Project Management Routes */}
                            {context.permissions.project_management__details && <>

                                {context.permissions.project_management__details.includes('view') && <Route path="project" caseSensitive={false} element={<Projects />} />}
                                {context.permissions.project_management__details.includes('add') && <Route path="project/add" caseSensitive={false} element={<ProjectAdd />} />}

                            </>}

                            {/* Project Management Tabs Routes */}
                            {((context.permissions.project_management__details && context.permissions.project_management__details.includes('view')) || (context.permissions.project_management__choice_list && context.permissions.project_management__choice_list.includes('view')) || (context.permissions.project_management__design_and_approval && context.permissions.project_management__design_and_approval.includes('view')) || 
                            (context.permissions.project_management__pre_construction && context.permissions.project_management__pre_construction.includes('view')) || (context.permissions.project_management__construction && context.permissions.project_management__construction.includes('view')) || (context.permission.project_management__post_construction && context.permissions.project_management__post_construction.includes('view'))) &&
                                <Route path="project/:project_id/:project_step" caseSensitive={false} element={<ProjectEdit />} />
                            }

                        </>}

                        <Route path="*" caseSensitive={false} element={<NotFound />} />
                    </Route>
                </Routes>
            </Context.Provider>
        </>
    )
}
