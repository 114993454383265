import { now } from "lodash";
import { useEffect, useState, useContext } from "react";
import { GET_PROJECT_TASK_DETAILS, PROJECT_TASK_DETAILS_CREATE } from "../../../components/ApiRoutes";
import { downloadBase64File, fetchData, generateId, initialFormState, srcToBase64 } from "../../../components/Helper";
import Context from "../../../components/Context";

export const Attachment = (props) => {
    const [context] = useContext(Context)
    const [data, setData] = useState()
    const [reloadTable, setReloadTable] = useState()

    useEffect(() => {
        if(props.taskId && props.type === "attachment"){
            document.getElementById('attachmentModal').addEventListener('shown.bs.modal', function (event) {
                initialFormState("attachmentForm")
                let scroll = document.querySelector("#attachmentModalBody")
                scroll.scrollTop = scroll.scrollHeight;
                scroll.behavior = "smooth"
            })
            fetchData(`${GET_PROJECT_TASK_DETAILS}/${props.taskId}/attachment`, 'get', '', true, false, (res) => {
                if (res.status) {
                    setData(res.data)
                    setTimeout(() => {
                        let scroll = document.querySelector("#attachmentModalBody")
                        scroll.scrollTop = scroll.scrollHeight;
                        scroll.behavior = "smooth"
                    }, 0);
                }
            })
        }
    }, [props.taskId, reloadTable, props.type])

    const addAttachment = (e) => {
        let attachment = document.getElementById("attachment")
        if(attachment.files.length){
            let formdata = new FormData(document.getElementById("attachmentForm"))
            formdata.append("type", "attachment")
            formdata.append("project_task_id", props.taskId)
            fetchData(PROJECT_TASK_DETAILS_CREATE, 'POST', formdata, true, true, (res) => {
                if(res.status) {
                    setReloadTable(now)
                    initialFormState("attachmentForm")
                }
            })
        }
    }

    const downloadAttachment = (e, url) => {
        e.target.classList.add("disabled")
        let name = generateId(25)
        srcToBase64(url, res => {
            e.target.classList.remove("disabled")
            downloadBase64File(res, name)
        })
    }

    return (
        <>
        <style jsx="true">{`
            .image-download-btn{
                position: absolute;
                left: 0;
            }
        `}</style>
        <div className="modal fade" id="attachmentModal" tabIndex="-1" data-bs-backdrop="static" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content border-0 shadow">
                    <div className="modal-header bg-light">
                        <h5 className="modal-title">Attachment</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" id='closeAttachmentModal' onClick={props.closeModal} aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-sm-4" style={{maxHeight: "400px"}} id="attachmentModalBody">
                        {data && data.map((message, index) => {
                            return (
                                <div className={`rounded-10 border-0 mb-2 shadow-none py-2 px-4 fs-14px ${message.user && message.user.role && message.user.role.id === 1 ? "bg-light-blue" : "bg-light-info"}`} key={index}>
                                    <p className="m-0">Added By <b>{message.user && message.user.name} ({message.user && message.user.role && message.user.role.name})</b></p>
                                    <div className="form-fileview mt-1">
                                    {message.value.map((value, key) => {
                                        return (
                                            <div className="bg-white position-relative rounded mt-2 me-2" key={key} style={{height: "100px"}}>
                                                {['jpeg', 'jpg', 'png'].includes(value.split('.').pop()) && <img className="rounded h-100" src={process.env.REACT_APP_ASSET_URL + value} alt="attachment" />}
                                                {['ogx', 'oga', 'ogv', 'mp4', 'mov', 'ogg', 'webm'].includes(value.split('.').pop()) && <video className="rounded h-100" src={process.env.REACT_APP_ASSET_URL + value} controls />}
                                                {['doc', 'docx'].includes(value.split('.').pop()) && <img className="rounded m-2 h-75" src="/images/google-docs.png" alt="attachment" />}
                                                {['xls', 'xlsx'].includes(value.split('.').pop()) && <img className="rounded m-2 h-75" src="/images/excel-file.png" alt="attachment" />}
                                                {['pdf'].includes(value.split('.').pop()) && <img className="rounded m-2 h-75" src="/images/pdf.png" alt="attachment" />}

                                                <button className="image-download-btn btn btn-sm btn-secondary" type="button" title="Download" onClick={(e) => downloadAttachment(e, process.env.REACT_APP_ASSET_URL + value)}>
                                                    <i className="bi bi-download"></i>
                                                </button>
                                            </div>
                                        )
                                    })}
                                    </div>
                                    <p className="text-end m-0">{message.send_time}</p>
                                </div>
                            )
                        })}
                    </div>
                    {context && context.permissions && context.permissions.attachments.includes('add') &&
                        <div className="modal-footer bg-light">
                            <form className="w-100" id="attachmentForm" action="#" method="post">
                                <div className="input-group">
                                    <input type="file" className="form-control" accept=".png,.jpg,.jpeg,.ogx,.oga,.ogv,.mp4,.mov,.ogg,.webm,.doc,.pdf,.docx,.xlsx" id="attachment" name="attachment[]" multiple required />
                                    <button type="button" className="btn btn-sm btn-blue" onClick={addAttachment} title="Send">
                                        <i className="bi bi-send-x-fill"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                    }
                </div>
            </div>
        </div>
        </>
    )
}