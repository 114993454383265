import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { PRE_CONSTRUCTION_CREATE, PRE_CONSTRUCTION_EDIT, PRE_CONSTRUCTION_UPDATE } from "../../components/ApiRoutes";
import { InputField, SubmitButton } from "../../components/FormHelper";
import { fetchData, validateForm } from "../../components/Helper";

function PreConstructionCreate() {
    const navigate = useNavigate();
    const preConstructionId = useParams().id
    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState()

    useEffect(() => {
        if (preConstructionId) {
            fetchData(`${PRE_CONSTRUCTION_EDIT}/${preConstructionId}`, 'get', '', true, false, (res) => {
                if (res.status) {
                    setData(res.data)
                }
            })
        }
    }, [preConstructionId])

    const createList = (e) => {
        e.preventDefault();
        if (validateForm(e)) {
            setTinyloader(true)
            let api_url = PRE_CONSTRUCTION_CREATE;
            let formdata = new FormData(document.getElementById('preConstructionCreateForm'))
            if (preConstructionId) {
                api_url = PRE_CONSTRUCTION_UPDATE
                formdata.append('id', preConstructionId)
            }
            fetchData(api_url, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    navigate(`/erp/pre-construction`)
                }
            })
        }
    }
    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col mb-2">
                        <h6 className="m-0 heading-lines fw-medium pb-2">
                            <Link to={`/erp/pre-construction`} className="text-decoration-none" title="Pre Construction List">Pre Construction List </Link>
                            <i className="bi bi-chevron-double-right fs-14px text-dark"></i> {preConstructionId ? 'Edit' : 'Add'} Pre Construction
                        </h6>
                    </div>
                    <div className="col-auto mb-2">
                        <Link to={`/erp/pre-construction`} className="btn btn-blue btn-sm mb-1 me-2" title="Pre Construction List"><i className="bi bi-list-task"></i> Pre Construction List</Link>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body p-sm-4 p-xl-5">
                                <form id="preConstructionCreateForm" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                                    <InputField name="name" value={data && data.name} required />
                                    <SubmitButton type="submit" load={tinyloader} title={preConstructionId ? "Update" : "Save"} action={createList} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PreConstructionCreate