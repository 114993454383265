import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export const EditButton = (props) => {
    return (
        <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={<Tooltip> {props.title ?? 'Edit'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-secondary me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-pencil-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const DeleteButton = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'Delete'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-danger me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-trash-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const PermissionButton = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'Permission'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-primary me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null} >
                <div className="spinner-border spinner-border-sm d-none" role="status"></div>
                <i className="bi bi-ui-checks-grid"></i>
            </button>
        </OverlayTrigger>
    )
}

export const ActiveButton = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'Activate'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-success me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-check-circle-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const DeactiveButton = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'Deactivate'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-warning me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-x-circle-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const InProgressButton = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'In Progress'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-info me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-forward-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const HoldButton = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'Hold'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-secondary me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-slash-circle-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const CompleteButton = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'Complete'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-success me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-check-circle"></i>
            </button>
        </OverlayTrigger>
    )
}

export const CancelButton = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'Cancel'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-danger me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-x-circle-fill"></i>
            </button>
        </OverlayTrigger>
    )
}


export const ViewButton = (props) => {
    return (
        <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={<Tooltip> {props.title ?? 'View'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-info me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-eye-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const ViewButtonModal = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'View'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-info me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-eye-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const NoteButtonModal = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'Note'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-info me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-journal-check"></i>
            </button>
        </OverlayTrigger>
    )
}

export const AttachmentButtonModal = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'Attachment'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-blue me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-paperclip"></i>
            </button>
        </OverlayTrigger>
    )
}

export const AddButtonModal = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'Add'} </Tooltip>}>
            <button id={props.id ?? null} className="btn btn-sm btn-outline-blue me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-plus-circle-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const ListButtonModal = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'List'} </Tooltip>}>
            <button id={props.id ?? null} className="btn btn-sm btn-outline-blue me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-list-task"></i>
            </button>
        </OverlayTrigger>
    )
}

export const OnGoingButton = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'On-going'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-info me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-forward-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const EditButtonModal = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'Edit'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-secondary me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-pencil-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const DragButton = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'Drag'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-blue me-1 action-btn drag">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grip-vertical" viewBox="3 4 8 8">
                    <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
            </button>
        </OverlayTrigger>
    )
}