import { useContext, useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GET_ACTIVE_CLIENT, GET_ACTIVE_PROJECT_MANAGER, PROJECT_UPDATE } from "../../../components/ApiRoutes";
import { InputField, SelectField, SubmitButton } from "../../../components/FormHelper";
import { fetchData, getPermission, validateForm } from "../../../components/Helper";
import { EditButton } from '../../../components/ButtonHelper';
import Context from "../../../components/Context";

function ProjectDetails(props) {
    const navigate = useNavigate();
    const [context] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);
    const [projectManager, setProjectManager] = useState();
    const [client, setClient] = useState();
    const projectStep = useParams().project_step
    const [edit, setEdit] = useState(false);
    const [access, setAccess] = useState({view: false, add: false, edit: false, delete: false})

    useEffect(() => setAccess(getPermission(context, "project_management__details")), [context])

    useEffect(() => {
        if(projectStep === 'details'){
            fetchData(GET_ACTIVE_PROJECT_MANAGER, 'get', '', true, false, (res) => {
                if (res.status) {
                    setProjectManager(res.data)
                }
            })
            fetchData(GET_ACTIVE_CLIENT, 'get', '', true, false, (res) => {
                if (res.status) {
                    setClient(res.data)
                }
            })
        }
        setEdit(false)
    }, [projectStep])

    const createList = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)

            let formdata = new FormData(document.getElementById('form-project-add'))
            formdata.append('id', props.project.id)

            fetchData(PROJECT_UPDATE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    navigate(`/erp/project`)
                }
            })
        }
    }

    return (
        <div className="col-sm-12 position-relative">
            {context && context.permissions && context.permissions.project_management__details && context.permissions.project_management__details.includes('edit') &&
                <div className="col-sm-12 text-sm-end position-absolute">
                    {!edit && access.edit && <EditButton action={() => setEdit(true)} />}
                </div>
            }
            <form id="form-project-add" action="#" method="post" className={`p-4 p-sm-5 ${edit ? 'needs-validation' : 'disable-form'}`} noValidate>
                {projectManager && client && <>
                    <InputField name="name" value={props.project.name} required />
                    <SelectField name="project_manager" value={props.project.project_manager_id} required>
                        {projectManager.map((user, index) => <option key={index} value={user.id}>{user.name}</option>)}
                    </SelectField>
                    <SelectField name="client" value={props.project.client_id} required>
                        {client.map((user, index) => <option key={index} value={user.id}>{user.name}</option>)}
                    </SelectField>
                    <InputField name="address" value={props.project.address} required />
                    {edit && <SubmitButton load={tinyloader} title="Update" action={createList} />}
                </>}
            </form>
        </div>
    )
}

export default ProjectDetails;