import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { INVENTORY_CREATE, INVENTORY_EDIT, INVENTORY_UPDATE } from "../../components/ApiRoutes";
import { InputField, SubmitButton } from "../../components/FormHelper";
import { fetchData, validateForm } from "../../components/Helper";

function ItemAdd() {
    const navigate = useNavigate();
    const location = useLocation();
    const itemId = useParams().id
    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState()

    useEffect(() => {
        if (itemId) {
            fetchData(`${INVENTORY_EDIT}/${itemId}`, 'get', '', true, false, (res) => {
                if (res.status) {
                    setData(res.data)
                }
            })
        }
    }, [location.state, itemId])

    const createList = (e) => {
        e.preventDefault();
        if (validateForm(e)) {
            setTinyloader(true)

            let formdata = new FormData(document.getElementById('form-inventoty-add-item'))

            if (itemId) {
                formdata.append('id', itemId)
            }

            fetchData(itemId ? INVENTORY_UPDATE : INVENTORY_CREATE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    navigate(`/erp/inventory`)
                }
            })
        }
    }
    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col mb-2">
                        <h6 className="m-0 heading-lines fw-medium pb-2">
                            <Link to={`/erp/inventory`} className="text-decoration-none" title="Inventory">Inventory </Link>
                            <i className="bi bi-chevron-double-right fs-14px text-dark"></i> {itemId ? 'Edit' : 'Add'} Item
                        </h6>
                    </div>
                    <div className="col-auto mb-2">
                        <Link to={`/erp/inventory`} className="btn btn-blue btn-sm mb-1 me-2" title="Inventory"><i className="bi bi-list-task"></i> Inventory</Link>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body p-sm-4 p-xl-5">
                                <form id="form-inventoty-add-item" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                                    <InputField name="name" value={data && data.name} required />
                                    <SubmitButton type="submit" load={tinyloader} title={itemId ? "Update" : "Save"} action={createList} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ItemAdd