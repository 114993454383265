import { createRoot } from 'react-dom/client'
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router';
import Datatables, { loadDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { CompleteButton, DeleteButton, ViewButton, InProgressButton, HoldButton, CancelButton } from '../../components/ButtonHelper';
import { PROJECT_CHANGE_STATUS, PROJECT_LIST } from '../../components/ApiRoutes';
import { ConfirmationModal, fetchData, getPermission } from '../../components/Helper';
import Context from '../../components/Context';

function Projects() {
    const navigate = useNavigate()
    const [context] = useContext(Context);
    const [deleteProjectId, setDeleteProjectId] = useState()
    const [access, setAccess] = useState({view: false, add: false, edit: false, delete: false})

    useEffect(() => setAccess(getPermission(context, "project_management__details")), [context])

    const [dt] = useState({
        dt_url: PROJECT_LIST,
        dt_name: 'project_list',
        dt_order: [[0, 'asc']],
        dt_column: [
            { data: "id", name: 'id', title: "#ID" },
            { data: "name", name: 'name', title: "Project Name" },
            { data: "project_manager.name", name: 'projectManager.name', title: "Project Manager" },
            { data: "client.name", name: 'client.name', title: "Client" },
            { data: "address", name: 'address', title: "Address" },
            { data: "status", name: 'status', title: "Status" },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],
        dt_column_defs: [
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        {records.status === "pending" && <span className="text-warning fw-medium">Pending</span>}
                        {records.status === "in_progress" && <span className="text-info fw-medium">In Progress</span>}
                        {records.status === "hold" && <span className="text-grey fw-medium">Hold</span>}
                        {records.status === "completed" && <span className="text-success fw-medium">Completed</span>}
                        {records.status === "cancel" && <span className="text-danger fw-medium">Cancel</span>}
                    </>)
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, records, row, col) => {
                    let permission = getPermission(context, "project_management__details")
                    let gotoView = 'details'
                    if(context && context.permission) {
                        if(context.permission.project_management__details && context.permissions.project_management__details.includes('view')) gotoView = 'details'
                        else if(context.permissions.project_management__choice_list && context.permissions.project_management__choice_list.includes('view')) gotoView = 'choice-list'
                        else if(context.permissions.project_management__design_and_approval && context.permissions.project_management__design_and_approval.includes('view')) gotoView = 'design-and-approval'
                        else if(context.permissions.project_management__pre_construction && context.permissions.project_management__pre_construction.includes('view')) gotoView = 'pre-construction'
                        else if(context.permissions.project_management__construction && context.permissions.project_management__construction.includes('view')) gotoView = 'construction'
                        else if(context.permissions.project_management__post_construction && context.permissions.project_management__post_construction.includes('view')) gotoView = 'post-construction'
                    }

                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            {permission.edit && <>
                                {records.status === 'pending' && <InProgressButton action={() => handleChangeStatus('in_progress', records.id)} /> }
                                {records.status === 'in_progress' && <HoldButton action={() => handleChangeStatus('hold', records.id)} /> } 
                                {records.status === 'hold' && <InProgressButton action={() => handleChangeStatus('in_progress', records.id)} />}
                                {(records.status === 'in_progress' || records.status === 'hold' ) && <CompleteButton action={() => handleChangeStatus('completed', records.id)} />}
                                {(records.status !== "cancel" && records.status !== "completed") && <CancelButton modal_id="#projectCancelModal" action={() => setDeleteProjectId(records.id)} />}
                            </>}

                            <ViewButton action={() => navigate(`/erp/project/${records.id}/${gotoView}`)} />
                            {permission.delete && <DeleteButton modal_id="#projectDeleteModal" action={() => setDeleteProjectId(records.id)} />}
                        </div>
                    </>)
                }
            }
        ],
    })

    const handleChangeStatus = (status, id) => {
        fetchData(PROJECT_CHANGE_STATUS, 'POST', { 'id': id, 'status': status }, true, false, (res) => {
            loadDataTable(dt)
        })
    }

    useEffect(() => {
        reloadUrlDataTable(dt, PROJECT_LIST)
    }, [dt])

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col mb-3">
                            <h6 className="m-0 heading-lines fw-medium pb-2">Projects</h6>
                        </div>
                        <div className="col-auto mb-2">
                            {access.add && <Link to={`/erp/project/add`} className="btn btn-blue btn-sm px-3 mb-1 me-2" title="Add Items"><i className="bi bi-plus-circle-fill me-1"></i> Add New Project</Link>}
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <Datatables dt_name="project_list" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ConfirmationModal modal_id="projectDeleteModal" title="Delete Project" action={() => handleChangeStatus('delete', deleteProjectId)}>
                <p className="">Are you sure want to delete this project?</p>
            </ConfirmationModal>
            <ConfirmationModal modal_id="projectCancelModal" title="Cancel Project" operation="Confirm" action={() => handleChangeStatus('cancel', deleteProjectId)}>
                <p className="">Are you sure want to cancel this project?</p>
            </ConfirmationModal>
        </>
    )
}

export default Projects