import { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from 'react-router';
import { PROJECT_EDIT, WEATHER_INFO } from "../../components/ApiRoutes";
import { fetchData } from "../../components/Helper";
import ChoiceList from "./ProjectSteps/ChoiceList";
import ProjectDetails from "./ProjectSteps/ProjectDetails";
import TaskList from "./ProjectSteps/TaskList";
import Context from "../../components/Context";
import NotFound from "../../components/NotFound";


function ProjectEdit() {
    const navigate = useNavigate()
    const [context] = useContext(Context);
    const projectId = useParams().project_id
    const projectStep = useParams().project_step
    const [project, setProject] = useState()
    const [accessPage, setAccessPage] = useState(true)
    const [weatherInfo,setWeatherInfo] = useState();
    const tabChange = (type) => {
        navigate(`/erp/project/${projectId}/${type}`)
    }

    useEffect(() => {
        if (projectId) {
            fetchData(`${PROJECT_EDIT}/${projectId}`, 'get', '', true, false, (res) => {
                if (res.status) {
                    if(context && context.auth && context.auth.role_id) {
                        if((context.auth.role_id === 3 && context.auth.id !== res.data.project_manager_id) || (context.auth.role_id === 4 && context.auth.id !== res.data.client_id)) {
                            setAccessPage(false)
                        }
                    }
                    setProject(res.data)
                    let location = res.data.address;

                    fetchData(WEATHER_INFO, 'POST', {location: location}, true, false, (res) => {
                        if(res.status){
                            setWeatherInfo(res.data);
                        }
                    });
                } else {
                    setAccessPage(false)
                }
            })
        }
    }, [projectId, context])

    return (
        <>
        {accessPage ?
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col mb-3">
                            <h6 className="m-0 heading-lines fw-medium pb-2">
                                <Link to={`/erp/project`} className="text-decoration-none" title="Projects">Projects </Link>
                            </h6>
                        </div>
                        <div className="col-auto mb-2">
                            <span className="badge bg-info fs-16px rounded-25"><i className="bi bi-building-fill-gear"></i> {project && project.name}</span>
                        </div>
                        <div className="col mb-2">
                            <h6 className="m-0 fw-medium pb-2">
                                <span className="badge bg-info fs-16px rounded-25"><i className="bi bi-geo-alt-fill"></i> {project && project.address && project.address}</span>
                            </h6>
                        </div>
                        <div className="col-auto mb-2 weather-card-info">
                        {weatherInfo && weatherInfo.current.condition  && weatherInfo.current.condition.text &&
                                <>
                                {   weatherInfo.current.condition.text.search(/Cloudy/i) >= 0 ? <> <i className="bi bi-cloudy-fill weather-card-info-icon text-info"></i></>
                                    :
                                    weatherInfo.current.condition.text.search(/Rain/i) >= 0 ?   <><i className="bi bi-cloud-rain weather-card-info-icon text-info"></i></>
                                    :
                                    weatherInfo.current.condition.text.search(/overcast/i) >= 0 ?   <><i className="bi bi-cloud-haze2-fill weather-card-info-icon text-info"></i></>
                                    :
                                    weatherInfo.current.condition.text.search(/sunny/i) >= 0 ?   <> <i className="bi bi-cloud-sun weather-card-info-icon text-info"></i></>
                                    :
                                    weatherInfo.current.condition.text.search(/clear/i) >= 0 ?   <><i className="bi bi-brightness-high weather-card-info-icon text-info"></i></>
                                    :
                                    weatherInfo.current.condition.text.search(/fog/i) >= 0 ?   <><i className="bi bi-cloud-fog weather-card-info-icon text-info"></i></>
                                    :
                                    weatherInfo.current.condition.text.search(/mist/i) >= 0 ?   <><i className="bi bi-cloud-haze weather-card-info-icon text-info"></i></>
                                    :
                                    <><i className="bi bi-cloud weather-card-info-icon text-info"></i></>
                                }
                                </>
                                }
                    
                            <div className="badge bg-info fs-16px rounded-25">{weatherInfo && weatherInfo.current.condition  && weatherInfo.current.condition.text} | {weatherInfo && weatherInfo.forecast && weatherInfo.forecast.forecastday && weatherInfo.forecast.forecastday[0].day && weatherInfo.forecast.forecastday[0].day.maxtemp_c}/{weatherInfo && weatherInfo.forecast && weatherInfo.forecast.forecastday && weatherInfo.forecast.forecastday[0].day && weatherInfo.forecast.forecastday[0].day.mintemp_c} &#8451;</div>
                            
                        </div>
                        <div className="col-auto mb-2">
                            <Link to={`/erp/project`} className="btn btn-blue btn-sm mb-1 me-2" title="Project's List"><i className="bi bi-list-task"></i> Project's List</Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <ul className="nav nav-tabs loading-data" id="myTab" role="tablist">
                                        {context && context.permissions && 
                                        <>
                                            {context.permissions.project_management__details && context.permissions.project_management__details.includes('view') &&
                                                <li className="nav-item" role="presentation">
                                                    <button className={projectStep === 'details' ? 'nav-link text-blue fw-medium active' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target="#details" type="button" role="tab" data-tablename="choice-list" onClick={() => tabChange('details')}>Project Details</button>
                                                </li>
                                            }
                                            {context.permissions.project_management__choice_list && context.permissions.project_management__choice_list.includes('view') && 
                                                <li className="nav-item" role="presentation">
                                                    <button className={projectStep === 'choice-list' ? 'nav-link text-blue fw-medium active' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target="#choiceList" type="button" role="tab" data-tablename="choice-list" onClick={() => tabChange('choice-list')}>Choice List</button>
                                                </li>
                                            }
                                            {context.permissions.project_management__design_and_approval && context.permissions.project_management__design_and_approval.includes('view') &&
                                                <li className="nav-item" role="presentation">
                                                    <button className={projectStep === 'design-and-approval' ? 'nav-link text-blue fw-medium active' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target="#designApproval" type="button" role="tab" data-tablename="design-and-approval" onClick={() => tabChange('design-and-approval')}>Design and Approval</button>
                                                </li>
                                            }
                                            {context.permissions.project_management__pre_construction && context.permissions.project_management__pre_construction.includes('view') &&
                                                <li className="nav-item" role="presentation">
                                                    <button className={projectStep === 'pre-construction' ? 'nav-link text-blue fw-medium active' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target="#designApproval" type="button" role="tab" data-tablename="pre-construction" onClick={() => tabChange('pre-construction')}>Pre Construction</button>
                                                </li>
                                            }
                                            {context.permissions.project_management__construction && context.permissions.project_management__construction.includes('view') && 
                                                <li className="nav-item" role="presentation">
                                                    <button className={projectStep === 'construction' ? 'nav-link text-blue fw-medium active' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target="#designApproval" type="button" role="tab" data-tablename="construction" onClick={() => tabChange('construction')}>Construction</button>
                                                </li>
                                            }
                                            {context.permissions.project_management__post_construction && context.permissions.project_management__post_construction.includes('view') &&
                                                <li className="nav-item" role="presentation">
                                                    <button className={projectStep === 'post-construction' ? 'nav-link text-blue fw-medium active' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target="#designApproval" type="button" role="tab" data-tablename="post-construction" onClick={() => tabChange('post-construction')}>Post Construction</button>
                                                </li>
                                            }
                                        </>
                                        }
                                    </ul>
                                    <div className="tab-content border border-top-0">
                                        <div className={projectStep === 'details' ? 'tab-pane fade p-3 show active' : 'tab-pane fade p-3 show'} id='details' role="tabpanel">
                                            {project && <ProjectDetails project={project} />}
                                        </div>
                                        <div className={projectStep === 'choice-list' ? 'tab-pane fade p-3 show active' : 'tab-pane fade p-3 show'} id='choiceList' role="tabpanel">
                                            {projectStep === 'choice-list' && project && <ChoiceList project={project} />}
                                        </div>
                                        <div className={projectStep !== 'choice-list' && projectStep !== 'details' ? 'tab-pane fade p-3 show active' : 'tab-pane fade p-3 show'} id='designApproval' role="tabpanel">
                                            {projectStep !== 'details' && projectStep !== 'choice-list' && <TaskList />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        : <NotFound />}
        </>
    )
}

export default ProjectEdit