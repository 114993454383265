import { useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { fetchData, validateForm } from '../components/Helper'

const ResetPassword = () => {

    let location = useLocation();
    const navigate = useNavigate();
    const url_token = location.search;

    if(url_token.split('=')[0] !== '?token'){
        navigate('/login');
    }
   
    const token = url_token.split("=")[1];
    const [data, setData] = useState({
        password: '',
        password_confirmation: '',
        token: token,
    })

    const handleInputChange = (e) => {
        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const sendData = (e) => {
        e.preventDefault()
        if (validateForm(e)) {
            fetchData('reset-password', 'POST', data, false, false, (res) => {
                if (res.status) {
                    setData({
                        password: '',
                        confirm_password: ''
                    })
                    navigate('/login')
                }
            })
        }
    }

    const showPassword = (e, getid) => {
        let bt = e.target;
        let ps = document.getElementById(getid);
        if (ps.getAttribute('type') === 'password') {
            ps.setAttribute('type', 'text');
            bt.innerHTML = '<i class="bi-eye-slash fs-5 pe-none"></i>'
        } else {
            ps.setAttribute('type', 'password');
            bt.innerHTML = '<i class="bi-eye fs-5 pe-none"></i>'
        }
    }

    return (
        <section className="login-section">
            <div className="container">
                <div className="row g-0 rounded-10 shadow overflow-hidden">
                    <div className="col-md-6 col-lg-5 bg-white d-flex align-items-center">
                        <form action="#" method="post" className="p-4 p-sm-5 w-100 needs-validation" noValidate>
                            <h4 className="heading-lines mb-4 mt-0">Reset Password</h4>

                            <div className="form-floating mb-3">
                                <input type="password" className="form-control" id="password" name="password" onChange={handleInputChange} placeholder="name@example.com" required />
                                <label htmlFor="floatingInput">Password</label>
                                <button type="button" onClick={(e) => showPassword(e, 'password')} className="btn position-absolute px-1 me-4 top-0 end-0 translate-middle-y mt-4 pt-3 shadow-none">
                                    <i className="bi-eye fs-5 pe-none"></i>
                                </button>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="password" className="form-control" id="password_confirmation" name="password_confirmation" onChange={handleInputChange} placeholder="name@example.com" required />
                                <label htmlFor="floatingInput">Confirm Password</label>
                                <button type="button" onClick={(e) => showPassword(e, 'password_confirmation')} className="btn position-absolute px-1 me-4 top-0 end-0 translate-middle-y mt-4 pt-3 shadow-none">
                                    <i className="bi-eye fs-5 pe-none"></i>
                                </button>
                            </div>
                            <div className="mb-4">
                                <button type="submit" onClick={sendData} className="btn btn-blue btn-lg w-100" title='RESET'>RESET</button>
                            </div>
                            <div className="d-flex justify-content-between pb-3">
                                <Link to={`/login`} className="anchor-blueorange fw-medium" title='Login'>Login</Link>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 col-lg-7">
                        <img src="/images/logo.png" className="w-100" alt="ausdreambuilt" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ResetPassword
