import { now, trim } from "lodash";
import { useEffect, useState, useContext } from "react";
import { GET_PROJECT_TASK_DETAILS, PROJECT_TASK_DETAILS_CREATE, PROJECT_TASK_DETAILS_DELETE, PROJECT_TASK_DETAILS_UPDATE } from "../../../components/ApiRoutes";
import { fetchData } from "../../../components/Helper";
import Context from "../../../components/Context";

export const Note = (props) => {
    const [context] = useContext(Context)
    const [note, setNote] = useState()
    const [data, setData] = useState()
    const [reloadTable, setReloadTable] = useState()
    const [noteId, setNoteId] = useState(null)

    useEffect(() => {
        if(props.taskId && props.type === "note"){
            document.getElementById('noteModal').addEventListener('shown.bs.modal', function (event) {
                let scroll = document.querySelector("#noteModalBody")
                scroll.scrollTop = scroll.scrollHeight;
                scroll.behavior = "smooth"
            })
            fetchData(`${GET_PROJECT_TASK_DETAILS}/${props.taskId}/note`, 'get', '', true, false, (res) => {
                if (res.status) {
                    setData(res.data)
                    setTimeout(() => {
                        let scroll = document.querySelector("#noteModalBody")
                        scroll.scrollTop = scroll.scrollHeight;
                        scroll.behavior = "smooth"
                    }, 0);
                }
            })
        }
    }, [props.taskId, reloadTable, props.type])

    const addNote = () => {
        if(trim(note)){
            let formdata = {type: 'note', value: note, project_task_id: props.taskId, id: noteId}
            fetchData(noteId ? PROJECT_TASK_DETAILS_UPDATE : PROJECT_TASK_DETAILS_CREATE, 'POST', formdata, true, false, (res) => {
                if(res.status) {
                    setReloadTable(now)
                    setNoteId(null)
                    setNote('')
                }
            })
        }
        
    }

    const stopNote = () => {
        setNoteId(null)
        setNote('')
    }

    const editNote = (data) => {
        setNote(data.value)
        setNoteId(data.id)
    }

    const deleteNote = (data) => {
        fetchData(PROJECT_TASK_DETAILS_DELETE, 'POST', {id: data.id}, true, false, (res) => {
            if(res.status) {
                setReloadTable(now)
            }
        })
    }

    return (
        <>
        <style jsx="true">{`
            #noteModal textarea{
                overflow: auto;
                resize: none;
                outline: none;
            }
            #noteModal textarea:focus{
                box-shadow: 0 0 0 0 rgb(13 110 253 / 25%) !important;
            }
        `}</style>

        <div className="modal fade" id="noteModal" tabIndex="-1" data-bs-backdrop="static" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content border-0 shadow">
                    <div className="modal-header bg-light">
                        <h5 className="modal-title">Note</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" id='closeNoteModal' onClick={props.closeModal} aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-sm-4" style={{maxHeight: "400px"}} id="noteModalBody">
                        {data && data.map((message, index) => {
                            return (<>
                            <div className="position-relative" key={index}>
                                { noteId !== message.id &&
                                <div className="text-sm-end w-100 p-1 position-absolute">
                                    {props.permissions && props.permissions.includes('edit') &&
                                        <span className="text-muted me-1" title="Edit" role="button" onClick={() => editNote(message)}><i className="bi bi-pencil-fill"></i></span>
                                    }
                                    {props.permissions && props.permissions.includes('delete') &&
                                        <span className="text-danger ms-1" title="Delete" role="button" onClick={() => deleteNote(message)}><i className="bi bi-trash"></i></span>
                                    }
                                </div> }
                                
                                <div className={`rounded-10 border-0 mb-2 shadow-none py-2 px-4 fs-14px ${message.user && message.user.role && message.user.role.id === 1 ? "bg-light-blue" : "bg-light-info"}`} key={index}>
                                    <p className="m-0">
                                        <span>Added By <b>{message.user && message.user.name} ({message.user && message.user.role && message.user.role.name})</b></span>
                                        {message.updated_by && message.updated_by.name && <>
                                            <span className="ms-4">Updated By <b>{message.updated_by.name} ({message.updated_by.role && message.updated_by.role.name})</b></span>
                                        </>}
                                    </p>
                                    <p className="m-0 opacity-70">{message.value}</p>
                                    <p className="text-end m-0">{message.send_time}</p>
                                </div>
                                </div>
                            </>)
                        })}
                    </div>
                    {context && context.permissions && context.permissions.notes.includes('add') &&
                        <div className="modal-footer bg-light">
                            <div className="input-group">
                                <span className="input-group-text">Note</span>
                                <textarea className="form-control" id="note" aria-label="With textarea" rows={1} placeholder="Write your notes ..." onChange={(e) => setNote(e.target.value)} value={note} />
                                { noteId && <button className="btn btn-sm btn-danger" onClick={stopNote} title="Cancel">
                                    <i className="bi bi-x-circle-fill"></i>
                                </button> }
                                <button className="btn btn-sm btn-blue" onClick={addNote} title="Send">
                                    <i className="bi bi-send-x-fill"></i>
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
        </>
    )
}