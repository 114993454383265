import { createRoot } from 'react-dom/client'
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router';
import Datatables, { loadDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { DeleteButton, EditButton, ViewButton } from '../../components/ButtonHelper';
import { INVENTORY_CHANGE_STATUS, INVENTORY_LIST } from '../../components/ApiRoutes';
import { ConfirmationModal, fetchData, getPermission } from '../../components/Helper';
import Context from '../../components/Context';

function InventoryList() {
    const navigate = useNavigate()
    const [context] = useContext(Context)
    const [deleteItemId, setDeleteItemId] = useState()
    const [access, setAccess] = useState({view: false, add: false, edit: false, delete: false})

    useEffect(() => setAccess(getPermission(context, "inventory")), [context])

    const [dt] = useState({
        dt_url: INVENTORY_LIST,
        dt_name: 'inventory_list',
        dt_order: [[0, 'asc']],
        dt_column: [
            { data: "id", name: 'id', title: "#ID" },
            { data: "name", name: 'name', title: "Item Name" },
            { data: "stock", name: 'stock', title: "In Stock", sortable: false, searchable: false },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],
        dt_column_defs: [
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    let permission = getPermission(context, "inventory")

                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            <ViewButton action={() => navigate(`/erp/inventory/${records.id}`)} />
                            {permission.edit && <EditButton action={() => navigate(`/erp/inventory/edit/${records.id}`)} />}
                            {permission.delete && <DeleteButton modal_id="#itemDeleteModal" action={() => setDeleteItemId(records.id)} />}
                        </div>
                    </>)
                }
            }
        ],
    })

    const handleChangeStatus = (status, id) => {
        fetchData(INVENTORY_CHANGE_STATUS, 'POST', { 'id': id, 'status': status }, true, false, (res) => {
            loadDataTable(dt)
        })
    }

    useEffect(() => {
        reloadUrlDataTable(dt, INVENTORY_LIST)
    }, [dt])

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col mb-3">
                            <h6 className="m-0 heading-lines fw-medium pb-2">Inventory</h6>
                        </div>
                        <div className="col-auto mb-2">
                            {access.add && <Link to={`/erp/inventory/add`} className="btn btn-blue btn-sm px-3 mb-1 me-2" title="Add Items"><i className="bi bi-plus-circle-fill me-1"></i> Add Items</Link>}
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <Datatables dt_name="inventory_list" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ConfirmationModal modal_id="itemDeleteModal" title="Delete Item" action={() => handleChangeStatus('delete', deleteItemId)}>
                <p className="">Are you sure want to delete this item?</p>
            </ConfirmationModal>
        </>
    )
}

export default InventoryList